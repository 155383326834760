import Chart from 'react-apexcharts';
import React, { Component, Fragment } from 'react';
import { Grid } from '@material-ui/core';
import { Card, CardContent, Tooltip } from '@material-ui/core';
import { HelpOutline } from '@material-ui/icons';
import exportOptions from '../chartExportOptions';
const info = <HelpOutline style={{ fontSize: 15 }}/>;

class MeetingStatisticsTable extends Component {
  constructor(props) {
    super(props);
    this.state = { loading: true };
  }



  async componentDidMount() {
    const series = {agenda:[], audience:[]};

    const categories = [];
    for(const serie of this.props.histogram){
      categories.push(serie.key_as_string);
      for(const key of Object.keys(serie.agenda_range)){
        if(!series["agenda"].find(i => i.id === key))
          series["agenda"].push({id:key, type:"bar", data:[]});
        const s = series["agenda"].find(i => i.id === key);
        s.data.push(serie.agenda_range[key]);
      }

      for(const key of Object.keys(serie.audience)){
        if(!series["audience"].find(i => i.id === key))
          series["audience"].push({id:key, type:"line", data:[]});
        const s = series["audience"].find(i => i.id === key);
        s.data.push(serie.audience[key]?serie.audience[key]:null);
      }
    }
    const audienceType = [];
    audienceType.push({...series.audience.find(i => i.id === "external"), name: "External"});
    audienceType.push({...series.audience.find(i => i.id === "internal"), name: "Internal"});
    audienceType.push({...series.audience.find(i => i.id === "one2one"), name: "One on One"});
    audienceType.push({...series.audience.find(i => i.id === "group"), name: "Groups"});

    const agendaType = [];
    agendaType.push({...series.agenda.find(i => i.id === "no"), name: "No agenda"});
    agendaType.push({...series.agenda.find(i => i.id === "short"), name: "Short agenda"});
    agendaType.push({...series.agenda.find(i => i.id === "long"), name: "Agenda"});

    this.setState({ loading: false, categories, audienceType, agendaType });
    //this.props.timestamp("meetingMetrics processing done")


  }


  render() {

    if (this.state.loading)
      return null;

    const commonConfig = {
      chart: { animations: { enabled: false }, toolbar: { show: false }, ...exportOptions("Date", "Count") },
      dataLabels: { enabled: false },
      xaxis: { type: "datetime", labels: { show: true }, crosshairs: { width: 1 }, categories: this.state.categories ? this.state.categories : [] },
      legend: { position: "right", show: true },
      theme: { mode: 'light', palette: 'palette2', },
      markers: { size: 3, strokeColors: '#fff', strokeWidth: 1, shape: "circle", radius: 2, }
    };

    const agendaConfig = {
      ...commonConfig,
      chart: {...commonConfig.chart, stacked: true, stackType: "100%" },
      yaxis: { labels: {formatter: (val) => { return val.toFixed(0) + "%"; } }},
      tooltip: { y: { formatter: function(value, { dataPointIndex, w }) {
        const percentage = value/w.config.series.reduce((acc,cur) => acc+=cur.data[dataPointIndex],0)*100;
        return percentage.toFixed(1) +"% (" + value + ")";
      }}},
    };

    const audienceConfig = {
      ...commonConfig,
    };

    return (
      <Fragment >
        <Card className="card-box mb-4">
          <CardContent style={{width:"100%"}}>
            <div className=" font-weight-bold font-size-xl text-uppercase" >Calendar and Meeting Trends</div>
            <Grid container spacing={4}>
              <Grid item xs={12} md={6}>
                <div className="d-flex align-items-start">
                  <Tooltip title="Here's the agenda information from your meetings. No agenda means the description field of the calendar event is empty (or near empty). Short agenda means the event has a description which is ~short paragraph. Agenda means when there is a comprehensive agenda in place.">
                    <div className="font-weight-bold mr-3">Meeting agenda breakdown {info}</div>
                  </Tooltip>
                </div>
                <Chart options={agendaConfig} series={this.state.agendaType} type="bar" height="200"/>
              </Grid>
              <Grid item xs={12} md={6}>
                <div className="d-flex align-items-start">
                  <Tooltip title="Here you can see how many meetings by the type of audiences. Audience is classed as either internal, or external. Additionally we show you if the meeting had only 2 participants, or if the invite list included groups.">
                    <div className="font-weight-bold mr-3"># Meetings by the audience type {info}</div>
                  </Tooltip>
                </div>
                <Chart options={audienceConfig} series={this.state.audienceType} type="line" height="200"/>
              </Grid>
            </Grid>
          </CardContent>
         </Card>
        </Fragment>
    ); // end of return

  } // end of render()

} // end of class

export default MeetingStatisticsTable
