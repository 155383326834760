import React, { Component, Fragment } from 'react';
import Loading from '../../../../flowtrace-components/Loading';
import TeamSelector from './teamSelector';
import { Card, CardContent, Button, Grid, FormControl, Switch, Select, MenuItem, Tooltip, List, ListItem, ListItemIcon, IconButton } from '@material-ui/core';
import { Delete, AddCircle } from '@material-ui/icons';
import accountAPI from '../../../../flowtrace-api/Account/propertyActions';

class AccountMembers extends Component {
  constructor(props) {
    super(props);
    const a = this.props.flowtraceUser.account ? this.props.flowtraceUser.account : null;
    const costProps = a && a.props.costConfiguration ? a.props.costConfiguration : null;
    const anonymize = a && a.props.anonymizeMembers ? true : false;

    if(costProps)
      this.state = {loading:false, loadingReason:null, reload:false, anonymize, teamConfiguration:[], ...costProps, changed : false};
    else
      this.state = {loading:false, loadingReason:null, reload:false, anonymize, teamConfiguration:[], currency:"GBP", hours:1750, salary:85, changed : this.props.onboarding ? true: false};
  }

  updateCurrency = (event) => { this.setState({changed:true, currency:event.target.value}); }
  updateSalary = (event) => {
    if(event.target.name){
      const index = event.target.name.split("|")[1];
      const teamConfiguration = this.state.teamConfiguration;
      teamConfiguration[index].salary = event.target.value;
      this.setState({changed:true, teamConfiguration});
    } else
      this.setState({changed:true, salary:event.target.value});
  }
  updateHours = (event) => {
    if(event.target.name){
      const index = event.target.name.split("|")[1];
      const teamConfiguration = this.state.teamConfiguration;
      teamConfiguration[index].hours = event.target.value;
      this.setState({changed:true, teamConfiguration});
    } else
      this.setState({changed:true, hours:event.target.value});

  }

  async updateSettings() {
    const settings = {costConfiguration:{currency:this.state.currency, hours:this.state.hours, salary:this.state.salary, teamConfiguration:this.state.teamConfiguration}};
    try{
      this.setState({loading:true});
      const a = this.props.flowtraceUser.account ? this.props.flowtraceUser.account : null;
      const costProps = a.props.costConfiguration ? a.props.costConfiguration : null;
      // Remove existing properties before adding a new one
      if(costProps)
        await accountAPI.deleteProperty(this.props.flowtraceUser.account.account_id, settings);
      const response = await accountAPI.addProperty(this.props.flowtraceUser.account.account_id, settings);

      // Check if we need to update anonymize members property:
      const anonymizeMembers = Object.keys(a.props).includes("anonymizeMembers") ? a.props.anonymizeMembers : null;
      if(anonymizeMembers !== null) {
        await accountAPI.deleteProperty(this.props.flowtraceUser.account.account_id, {anonymizeMembers});
      }
      await accountAPI.addProperty(this.props.flowtraceUser.account.account_id, {anonymizeMembers:this.state.anonymize});

      if(response.success){
        await this.props.flowtraceUser.reload();
        return this.setState({changed:false, reload:true, loading:false});
      }
    } catch (e) {
      console.error(e);
    }
    return this.setState({error:"Something went wrong.", loading:false});
  }

  async componentDidMount() {
    if(this.props.onboarding) {
      const a = this.props.flowtraceUser.account ? this.props.flowtraceUser.account : null;
      if(!a && !this.props.flowtraceUser.reloading) {
        this.setState({loading:true, loadingReason:"create_account"});
        const domain = this.props.flowtraceUser.email.split("@")[1];
        const response = await accountAPI.createAccount(domain);
        // If we cannot create the account we need to show loading and suggestion to contact our support
        if(response.success){
          await this.props.flowtraceUser.reload();
          return this.setState({changed:false, reload:true, loading:false, loadingReason:null});
        }
        else
          this.setState({loadingReason:"create_account_failed"});
      }
    }
  }
  async componentDidUpdate() {
    if(this.state.reload && !this.props.flowtraceUser.reloading){
      const a = this.props.flowtraceUser.account ? this.props.flowtraceUser.account : null;
      const costProps = a && a.props.costConfiguration ? a.props.costConfiguration : {};
      this.setState({reload:false, ...costProps});
    }
  }

  onAnonymizeChange = (e) => {
    this.setState({changed:true, anonymize : e.target.checked});
  }

  onTeamChange = (val, index) => {
    const teamConfiguration = this.state.teamConfiguration;
    teamConfiguration[index].team = val;
    this.setState({changed:true, teamConfiguration});
  }

  addTeamConfiguration = () => {
    const teamConfiguration = this.state.teamConfiguration;
    teamConfiguration.push({team:null, hours:this.state.hours, salary:this.state.salary});
    this.setState({changed:true, teamConfiguration});
  }
  deleteTeamConfiguration= (removeIndex) => {
    let teamConfiguration = this.state.teamConfiguration;
    teamConfiguration = teamConfiguration.filter((i, index) => index !== removeIndex);
    console.log(removeIndex, this.state.teamConfiguration, teamConfiguration)
    this.setState({changed:true, teamConfiguration});
  }

  // https://data.oecd.org/emp/hours-worked.htm
  // Average hours worked by employee in UK / year is 1730 https://clockify.me/working-hours
  // Average salary for tech company in UK is 74k https://www.computerweekly.com/news/252478407/Average-UK-tech-salary-grows-to-74000-a-year-in-2019
  // Average employe costs for employer in UK for 74k Gross are £84,216.92 using https://www.pcpayroll.co.uk/uk-salary-calculator/

  render() {
    if (this.state.loading && this.state.loadingReason === "create_account")
      return <Loading text="Creating your Flowtrace account. Please wait."/>;
    if (this.state.loading && this.state.loadingReason === "create_account_failed")
      return <Loading text="This is taking longer than usual. Please contact us if you keep on seeing this message."/>;
    if (this.state.loading)
      return <Loading text="Saving your new cost settings."/>;
    if (this.props.flowtraceUser.reloading)
      return <Loading text="Reloading account settings."/>;

    const currencies = [{id:"USD", label:"$"}, {id:"EUR", label:"€"}, {id:"GBP", label:"£"}, {id:"JPY", label:"¥"},{id:"AUD", label:"$"},{id:"CAD", label:"$"},{id:"CHF", label:"Fr"},{id:"SEK", label:"kr"}, {id:"ZAR", label:"R" }, {id:"BRL", label:"R$" }];
    const workingHours = [1400, 1450, 1500, 1550, 1600, 1650, 1700, 1750, 1800, 1850, 1900, 1950, 2000];
    const salary = [5, 6, 7, 8, 9, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95, 100, 110, 120, 130, 140, 150, 160, 170, 180, 190, 200, 225, 250, 275, 300, 350, 400, 450, 500, 550, 600, 650, 700, 750, 800, 850, 900, 950, 1000, 1100, 1200, 1300, 1400, 1500, 1600, 1700, 1800, 1900, 2000, 2500, 3000, 3500, 4000, 4500, 5000, 5500, 6000, 6500, 7000, 7500, 8000, 8500, 9000, 9500, 10000, 11000, 12000, 13000, 14000, 15000, 16000, 17000, 18000, 19000, 20000, 25000, 30000, 35000, 40000, 45000, 50000];

    const locale = Intl.NumberFormat().resolvedOptions().locale
    const selectors =
      <Grid item xs={12}>
        <FormControl>
          <Select value={this.state.currency} onChange={this.updateCurrency} displayEmpty variant="outlined" autoWidth={true}>
            <MenuItem value="" disabled>Select currency</MenuItem>
            {currencies.map(i => <MenuItem value={i.id} key={"currency-" + i.id}>{i.id + " (" + i.label + ")"}</MenuItem>)}
          </Select>
        </FormControl>
        <FormControl>
          <Select value={this.state.hours} onChange={this.updateHours} displayEmpty variant="outlined" autoWidth={true}>
            <MenuItem value="" disabled>Select yearly working hours</MenuItem>
            {workingHours.map(i => <MenuItem value={i} key={"hours-" + i}>{i + " hours / year"}</MenuItem>)}
          </Select>
        </FormControl>
        <FormControl>
          <Select value={this.state.salary} onChange={this.updateSalary} displayEmpty variant="outlined" autoWidth={true}>
            <MenuItem value="" disabled>Select approximate yearly gross salary</MenuItem>
            {salary.map(i => {
              const text = (i * 1000).toLocaleString(locale, {style: 'currency', currency: this.state.currency})
              return <MenuItem value={i} key={"salary-" + i}>{text}</MenuItem>
            })}
          </Select>
        </FormControl>
      </Grid>;
    const saveButton =
      <Grid item xs={12} sm={12} md={12}>
        <Button disabled={!this.state.changed} onClick={async() => await this.updateSettings()} size="medium" variant="contained" color="primary" className="ml-1 mt-3 mr-3">Save Settings</Button>
      </Grid>;

    const teamConfiguration =
      <Grid container spacing={2} direction="column" >
        <Grid item >
          <List dense={true}>
            {
              this.state.teamConfiguration.map((i, index) =>
                <ListItem key={"team-configuration-item-"+index+i.team}>
                  <ListItemIcon id={"team-configuration-item-icon"+index}>
                    <IconButton id={"team-configuration-item-icon-button-"+index} onClick={() => this.deleteTeamConfiguration(index)} aria-label="Delete this item">
                      <Delete id={"team-configuration-item-delete-"+index}/>
                    </IconButton>
                  </ListItemIcon>
                  <FormControl>
                    <Select id={"team-configuration-item-hours-"+index} value={i.hours} onChange={this.updateHours} name={"teamConfiguration|" + index} displayEmpty variant="outlined" autoWidth={true}>
                      <MenuItem value="" disabled>Select yearly working hours</MenuItem>
                      {workingHours.map(i => <MenuItem value={i} key={"hours-" + i}>{i + " hours / year"}</MenuItem>)}
                    </Select>
                  </FormControl>
                  <FormControl>
                    <Select id={"team-configuration-item-salary-"+index} value={i.salary} onChange={this.updateSalary} name={"teamConfiguration|" + index} displayEmpty variant="outlined" autoWidth={true}>
                      <MenuItem value="" disabled>Select approximate yearly gross salary</MenuItem>
                      {
                        salary.map(i => {
                          const text = (i * 1000).toLocaleString(locale, {style: 'currency', currency: this.state.currency})
                          return <MenuItem value={i} key={"salary-" + i}>{text}</MenuItem>
                      })}
                    </Select>
                  </FormControl>
                  <TeamSelector team={i.team} index={index} flowtraceUser={ this.props.flowtraceUser } onTeamChange={this.onTeamChange.bind(this)}/>
                </ListItem>
              )
            }
            <ListItem key={"team-configuration-add"}>
              <ListItemIcon>
                <IconButton onClick={() => this.addTeamConfiguration()} aria-label="Add new item">
                  <AddCircle  />
                </IconButton>
              </ListItemIcon>
            </ListItem>
          </List>
        </Grid>
      </Grid>;

    if(this.props.onboarding)
    return (
      <Fragment>
        <Grid container spacing={4} direction="column" >
        {selectors}
        {saveButton}
        </Grid>
      </Fragment>
    );



    return (
      <Fragment>
        <Card className="card-box mb-4">
          <CardContent className="p-4">
            <Grid container spacing={4} direction="column" >
              <Tooltip title={"This settings applies to all your meeting cost estimates in the Flowtrace platform. Example: In 2019 UK average yearly tech working hours were ~1'730 and average net salary was £73'000 resulting in gross of £84'217."}>
                <Grid item xs={12} sm={12} md={12}>
                  <h5 className="mb-5">Configure your company's cost estimate settings</h5>
                  <p>These settings are used for meeting cost estimates making the algorithm use your company's own context.</p>
                </Grid>
              </Tooltip>
              {selectors}
              <Tooltip title={"You can define here team average values to get more accurate estimates across your organization. These values are used in Flowtrace platform and in Meeting Cost for Google Calendar if you choose to enable the currency estimates."}>
                <Grid item xs={12} sm={12} md={12}>
                  <h5 className="mb-5">Team Cost Configuration</h5>
                  <p>You can choose to add team cost settings to estimate meeting costs more accurately.</p>
                </Grid>
              </Tooltip>
              {teamConfiguration}
              <Tooltip title={"You can choose to anonymize your users emails and names from the Flowtrace users. This setting is beneficial for custom privacy requirements. It can take up to 12 hours until all your currently available integration source users will be anonymized."}>
                <Grid item xs={12} sm={12} md={12}>
                  <h5 className="mb-5">User Anonymization</h5>
                  <p>Use this setting to anonymize all your user names and emails across the integration sources.</p>
                </Grid>
              </Tooltip>
              <Grid item xs={4}>
                <Switch checked={this.state.anonymize} onChange={this.onAnonymizeChange} color="primary" name="anonymize" inputProps={{ 'aria-label': 'primary checkbox' }} />
                {this.state.anonymize ? "User email addresses and names will be anonymized across the Flowtrace." : "Your user names will stay plain text in the Flowtrace." }
              </Grid>

              {saveButton}
            </Grid>
          </CardContent>
        </Card>
      </Fragment>
    );

  }
}

export default AccountMembers;
