import React from 'react';
import { SaveAlt } from '@material-ui/icons';
import { renderToStaticMarkup } from 'react-dom/server';

function extend (target) {
    for(var i=1; i<arguments.length; ++i) {
        var from = arguments[i];
        if(typeof from !== 'object') continue;
        for(var j in from) {
            if(from.hasOwnProperty(j)) {
                target[j] = typeof from[j]==='object'
                ? extend({}, target[j], from[j])
                : from[j];
            }
        }
    }
    return target;
}

const defaultDateFormatter = (d) => new Date(d).toISOString().split("T")[0];

const generate = (headerCategory, headerValue, categoryFormatter = null) => {
    const exportButton = {
      zoom:{allowMouseWheelZoom:false},
      toolbar:{
        show:true,
        offsetY: -10,
        tools:{
          download:renderToStaticMarkup(<SaveAlt />),
          selection:false,
          pan:false,
          zoom:false,
          zoomin:false,
          zoomout:false,
          reset:false,
        },
        autoSelected:"download",
        export:{
          csv:{
            filename: "export-" + (new Date()).toISOString().split(".")[0]
          }
        }
      }
    };
    const customExport = {
      toolbar:{
        export:{
          csv:{
            headerCategory,
            headerValue,
            categoryFormatter
          }
        }
      }
    };
    if(headerCategory.toLowerCase() === "date" && categoryFormatter === null)
      customExport.toolbar.export.csv.categoryFormatter = defaultDateFormatter;
    return extend(customExport, exportButton);
  };

export default generate;